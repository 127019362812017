import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [26,[2]],
		"/(no-auth)/access-denied": [123,[25]],
		"/(app)/assign-account-owner": [27,[2,3]],
		"/(app)/billing": [28,[2,4]],
		"/(app)/data-converter/[port]": [29,[2]],
		"/(no-auth)/debugger": [124,[25]],
		"/(app)/e2eUtils/clean-namespaces": [30,[2,5]],
		"/(app)/e2eUtils/clean-users": [31,[2,5]],
		"/(no-auth)/jwt-missing-claims": [125,[25]],
		"/(no-auth)/login-error": [126,[25]],
		"/(auth)/login": [121,[24]],
		"/(auth)/login/callback": [122,[24]],
		"/(no-auth)/logout": [127,[25]],
		"/(app)/namespaces": [32,[2]],
		"/(app)/namespaces/create": [61,[2]],
		"/(app)/namespaces/[namespace]": [33,[2]],
		"/(app)/namespaces/[namespace]/(core)/batch-operations": [34,[2,6]],
		"/(app)/namespaces/[namespace]/(core)/batch-operations/[jobId]": [35,[2,6]],
		"/(app)/namespaces/[namespace]/edit": [57,[2]],
		"/(app)/namespaces/[namespace]/export": [58,[2]],
		"/(app)/namespaces/[namespace]/failovers": [59,[2]],
		"/(app)/namespaces/[namespace]/(core)/schedules": [36,[2,6,7]],
		"/(app)/namespaces/[namespace]/(core)/schedules/create": [39,[2,6,7]],
		"/(app)/namespaces/[namespace]/(core)/schedules/[schedule]": [37,[2,6,7]],
		"/(app)/namespaces/[namespace]/(core)/schedules/[schedule]/edit": [38,[2,6,7]],
		"/(app)/namespaces/[namespace]/(core)/task-queues": [40,[2,6,8]],
		"/(app)/namespaces/[namespace]/(core)/task-queues/[queue]": [41,[2,6,8]],
		"/(app)/namespaces/[namespace]/usage": [60,[2]],
		"/(app)/namespaces/[namespace]/(core)/workflows": [42,[2,6,9]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]": [43,[2,6,9]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]": [44,[2,6,9,10]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/call-stack": [45,[2,6,9,10]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/history": [46,[2,6,9,10]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/history/compact": [47,[2,6,9,10]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/history/event-groups/[id]": [48,[2,6,9,10]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/history/events/[id]": [49,[2,6,9,10]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/history/feed": [50,[2,6,9,10]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/history/json": [51,[2,6,9,10]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/metadata": [52,[2,6,9,10]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/pending-activities": [53,[2,6,9,10]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/query": [54,[2,6,9,10]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/relationships": [55,[2,6,9,10]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/workers": [56,[2,6,9,10]],
		"/(app)/nexus": [62,[2,11]],
		"/(app)/nexus/create": [65,[2,11]],
		"/(app)/nexus/[id]": [63,[2,11,12]],
		"/(app)/nexus/[id]/edit": [64,[2,11,12]],
		"/(app)/profile": [66,[2,13]],
		"/(app)/profile/api-keys": [67,[2,13]],
		"/(app)/profile/api-keys/create": [69,[2]],
		"/(app)/profile/api-keys/[api_key]": [68,[2]],
		"/(app)/settings": [70,[2,14]],
		"/(app)/settings/api-keys": [71,[2,14]],
		"/(app)/settings/api-keys/create": [73,[2]],
		"/(app)/settings/api-keys/[api_key]": [72,[2,14]],
		"/(app)/settings/audit-logging": [74,[2,14]],
		"/(app)/settings/audit-logging/edit": [75,[2,14]],
		"/(app)/settings/identities": [76,[2,14]],
		"/(app)/settings/identities/service-accounts": [80,[2,14]],
		"/(app)/settings/identities/service-accounts/create": [81,[2,14]],
		"/(app)/settings/identities/[identity]": [77,[2,14,15]],
		"/(app)/settings/identities/[identity]/api-keys": [78,[2,14,15]],
		"/(app)/settings/identities/[identity]/edit": [79,[2]],
		"/(app)/settings/observability": [82,[2,14]],
		"/(app)/settings/service-accounts": [83,[2,14]],
		"/(app)/settings/service-accounts/[service_account]": [84,[2,14]],
		"/(app)/settings/service-accounts/[service_account]/edit": [85,[2,14]],
		"/(app)/settings/users": [86,[2,14]],
		"/(app)/settings/users/create": [90,[2,14]],
		"/(app)/settings/users/[user]": [87,[2,16]],
		"/(app)/settings/users/[user]/api-keys": [88,[2,16]],
		"/(app)/settings/users/[user]/edit": [89,[2]],
		"/(app)/support": [91,[2,17]],
		"/(app)/support/accounts": [92,[2,17,18]],
		"/(app)/support/accounts/aws-marketo": [98,[2,17,18]],
		"/(app)/support/accounts/create": [99,[2,17,18]],
		"/(app)/support/accounts/[accountid]": [93,[2,17,18]],
		"/(app)/support/accounts/[accountid]/configure-saml": [94,[2,17,18]],
		"/(app)/support/accounts/[accountid]/invite-user": [95,[2,17,18]],
		"/(app)/support/accounts/[accountid]/update-userlimit": [97,[2,17,18]],
		"/(app)/support/accounts/[accountid]/update": [96,[2,17,18]],
		"/(app)/support/cells": [100,[2,17,19]],
		"/(app)/support/cells/audit-cells": [102,[2,17,19]],
		"/(app)/support/cells/stats": [103,[2,17,19]],
		"/(app)/support/cells/[cellid]": [101,[2,17,19]],
		"/(app)/support/deploy": [104,[2,17,20]],
		"/(app)/support/deploy/deployments": [105,[2,17,20]],
		"/(app)/support/grant": [106,[2,17]],
		"/(app)/support/identities/api-keys": [107,[2,17,21]],
		"/(app)/support/identities/api-keys/[apikeyid]": [108,[2,17,21]],
		"/(app)/support/identities/service-accounts": [109,[2,17,21]],
		"/(app)/support/identities/service-accounts/[serviceaccountid]": [110,[2,17,21]],
		"/(app)/support/identities/users": [111,[2,17,21]],
		"/(app)/support/identities/users/[userid]": [112,[2,17,21]],
		"/(app)/support/namespaces": [113,[2,17,22]],
		"/(app)/support/namespaces/dynamic-conf-audit": [116,[2,17,22]],
		"/(app)/support/namespaces/[namespaceid]": [114,[2,17,22]],
		"/(app)/support/namespaces/[namespaceid]/update-config": [115,[2,17,22]],
		"/(app)/support/settings": [117,[2,17]],
		"/(app)/usage": [118,[2]],
		"/(app)/usage/[namespace]": [119,[2]],
		"/(no-auth)/use-sso": [128,[25]],
		"/(no-auth)/user-not-found": [129,[25]],
		"/(app)/welcome": [120,[2,23]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';